import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { AboutTitle } from '../components/titles/Title'
import FadeIn from "../components/FadeIn";
const Wrapper = styled.div`
	display:flex;
	/* border:solid 1px red; */
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const LeftText = styled.div`
	flex:1;
	/* border:solid 1px yellow; */
	padding-right:20px;
	padding-bottom:20px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%
	}
`;

const Title = styled.h1`
	margin-top:0;
	line-height:1.2;
`;

const SpanRed = styled.span`
	color:${props => props.theme.red};
`;
const EmailLink = styled.a`
	color:${props => props.theme.red};
	transition:${props => props.theme.trans};
	&:hover{
		color:${props => props.theme.grey};
	}
`;
const AboutPage = () => (
  <Layout>
    <SEO title="Boxas - About" />
    {/* <h1>About</h1>
    <p>Boxas ....</p>
    <Link to="/">Go back to the homepage</Link> */}
		<Wrapper>
			<LeftText>
				<AboutTitle />
				<FadeIn duration="900" delay="1000">
				<p>Since obtaining a BA (hons) in Interior Architectural Design degree in 1999 and a BSc (hons) in Architectural Technology degree in 2003 and being admitted into Chartered Membership of the Chartered Institute of Architectural Technologists, I have a wealth of experience on a wide-ranging and varied portfolio covering most construction sectors. I have experience including loft conversions, extensions, conversion of listed buildings, bespoke new build dwellings, housing estates and apartments in the residential sector. New build car showrooms, factory units, office refurbishments, public houses and the design of a rugby clubhouse with grandstand facilities in the commercial sector. I am able to take a project from inception to completion if the client requires, as well as project manage the design phase of any project. As an Accredited Energy Assessor, I can offer SAP calculations for new build dwellings and EPC’s at the end of the project. If you require computer generated images, then this is also a service that is available from Box&nbsp;<SpanRed>Architectural&nbsp;Services</SpanRed>&nbsp;Ltd.</p>
				<p>This business operates as a home-based private limited company that currently is VAT excluded ensuring a saving of twenty percent VAT on prospective fees, therefore, the business is in a unique position to offer competitive quotations due to reduced overheads to other professional practices. Box&nbsp;<SpanRed>Architectural&nbsp;Services</SpanRed>&nbsp;Ltd offers a full range of architectural services whilst assessing the needs of both the client and end users with the aid of an agreed project brief. Evaluating and advising upon environmental and regulatory applications which include measured surveys, Planning and Building Regulation applications and all associated work tailored to the clients agreed form of appointment, which may include site certification and contract administration. </p>
				<p>As both a Designer and Chartered Technologist, BOXAS offers a dynamic perspective on building design with a client-orientated philosophy. </p>
				<p>Please do not hesitate to contact <EmailLink href="mailto:neil@boxas.co.uk" title="Email: neil@boxas.co.uk">Neil Box</EmailLink> for a free no obligation quotation for my services and be assured that all projects are of interest irrespective of size. Out of hours appointments can be arranged in advance. </p>
				</FadeIn>
				
			</LeftText>

			
		</Wrapper>
  </Layout>
)

export default AboutPage
